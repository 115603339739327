import TuneIcon from '@mui/icons-material/Tune'
import { Box, Button, Divider, IconButton, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import filtersService from '../../services/filtersService'
import statisticsService from '../../services/statisticsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import {
  booleanRenderer,
  currencyHeaderRenderer,
  decimalNumberRenderer,
  decimalValueWithPercRenderer,
  integerValueWithPercRenderer,
  monthsToYearsMonthsUomRenderer,
  tierGridRenderer,
  translatedHeaderRenderer,
  yearsToYearsMonthsUomRenderer,
  percentageFormatter,
  decimalNumberGridRenderer
} from '../data/formatters/dataFormatters'
import StdDataGrid from '../data/stdDataGrid'
import '../data/stdDataGrid.css'
import StdRowCount from '../data/stdRowCount'
import StdNameSearchField from '../form/stdNameSearchField'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'

const TabSelect = (props) => (
  <StdSelect
    value={props.tabState}
    onChange={(e) => props.setTabState(e.target.value)}
    renderValue={(value) => <TranslatedContent code={value} />}
    sx={{
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontSize: '1.2rem',
      height: 25,
      width: 150,
      border: '1px solid #04A8A3',
      borderRadius: '25px',
      '&:hover': {
        color: '#04A8A3'
      }
    }}>
    <MenuItem id='general' key='general' value='general'>
      <TranslatedContent code='general' defaultValue='General' />
    </MenuItem>
    <MenuItem id='players' key='players' value='players'>
      <TranslatedContent code='players' defaultValue='Players' />
    </MenuItem>
    <MenuItem id='value' key='value' value='value'>
      <TranslatedContent code='value' defaultValue='Value' />
    </MenuItem>
    <MenuItem id='salary' key='salary' value='salary'>
      <TranslatedContent code='salary' defaultValue='Salary' />
    </MenuItem>
    <MenuItem id='kpi' key='kpi' value='kpi'>
      <TranslatedContent code='kpi' defaultValue='kpi' />
    </MenuItem>
  </StdSelect>
)

const StdClubsTable = forwardRef((props, ref) => {

  const clubsTotal = useSelector(state => state.clubs.total)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const [numOfClubs, setNumOfClubs] = useState(clubsTotal)
  const [numOfClubClusters, setNumOfClubClusters] = useState(clubClusters.length)

  const leagueNameRenderer = ({ cell, row }) => (
    <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault()
        navigate('/leagues/' + row.original.leagueId)
      }}>
      {cell.getValue()}&nbsp;({row.original.country})
    </Typography>
  )

  const foreignPlayersRenderer = ({ cell, row }) => integerValueWithPercRenderer(row.original.shareOfForeignPlayers)({ value: cell.getValue() })

  const U23PlayersRenderer = ({ cell, row }) => integerValueWithPercRenderer(row.original.shareOfU23Players)({ value: cell.getValue() })

  const foreignU23PlayersRenderer = ({ cell, row }) => {

    let value =  cell.getValue();
    let num = row.original.numOfPlayers;
    let percentage = ((value / num) * 100).toFixed(2);
  
    return integerValueWithPercRenderer(percentage)({ value });
  };
  

  const tierDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percSalaryNetClusterDeviation)({ value: cell.getValue() })

  const leagueDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percLeagueSalaryNetDeviation)({ value: cell.getValue() })

  const salaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.salaryPercDeviation)({ value: cell.getValue() })

  const leagueLogoRenderer = ({ row, cell }) => {
    const handleClick = () => {
      navigate('/clubs/' + row.original.masterClubId)
    };
  
    return (
      <Tooltip title={row.original.leagueName} placement='right-start'>
          <img 
            onClick={handleClick}
            src={'/img/leagues/table-logos/' + (cell.getValue()) + '.png'}
            height='32px'
            width='25px'
            style={{ cursor: 'pointer' }}
          />
      </Tooltip>
    );
  }

  const countColumn = {
    header: 'count',
    maxSize: 30,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', className: 'StdCountCell', sx: { color: '#FFFFFF', backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdCountCell' },
    Header: () => <></>,
    Cell: ({ cell }) => <StdRowCount cell={cell} rowId='clubId' />
  }

  const nameColumn = {
    accessorKey: 'shortName',
    header: 'name',
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', sx: { backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdNameCell', sx: { borderRightWidth: 4 } },
    Header: () => (
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
        <StdNameSearchField ref={ref} className='StdClubsNameSearchField' onSearch={props.filterByName} />
      </Box>
    ),
    Cell: ({ cell }) => (
      <Typography sx={{ color: '#F4D35E', fontSize: '1.5rem', fontWeight: 600, cursor: 'pointer' }}
        onClick={() => navigate('/clubs/' + cell.row.id)}>
        {cell.getValue()}
      </Typography>
    )
  }
console.log(props);
  const generalColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'Club City', accessorKey: 'city', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'left', className: 'StdColumn' } },
    { header: 'Club Revenues Last', accessorKey: 'lastRevenues', minSize: 190, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of Registered Players', accessorKey: 'numOfPlayers', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Stadium Owner', accessorKey: 'stadiumOwner', minSize: 150, Cell: ({ cell }) => booleanRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club Tier', accessorKey: 'cluster', minSize: 140, Cell: tierGridRenderer(numOfClubClusters), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club League', accessorKey: 'leagueName', minSize: 200, Cell: leagueNameRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    //{ header: 'Last Position', accessorKey: 'lastPosition', minSize: 180, enableSorting: false, Cell: () => 'COMING SOON', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'left', className: 'StdColumn' } },
    //{ header: 'leagueWinner', accessorKey: 'leagueWinner', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' }},
    //{ header: 'qualifForCup', accessorKey: 'qualifForCup', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' }},
    //{ header: 'relegation', accessorKey: 'relegation', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' }},
    { header: 'Club Revenues Projected', accessorKey: 'revenues', minSize: 200, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfClubClusters])

  const playersColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'Club Num Of Registered Players', accessorKey: 'numOfPlayers', minSize: 180, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of Internal Players', accessorKey: 'numOfOwnedPlayers', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of Loaned Players', accessorKey: 'numOfLoanedPlayers', minSize: 190, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of Borrowed Players', accessorKey: 'numOfBorrowedPlayers', minSize: 190, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of National Players', accessorKey: 'numOfNationalPlayers', minSize: 180, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of Foreigner Players', accessorKey: 'numOfForeignPlayers', minSize: 180, Cell: foreignPlayersRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Num Of U23 Players', accessorKey: 'numOfU23Players', minSize: 180, Cell: U23PlayersRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueNumOfU23ForeignPlayers', accessorKey: 'numOfU23ForeignPlayers', minSize: 180, Cell: foreignU23PlayersRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Avg Age', accessorKey: 'avgAge', minSize: 200, Cell: ({ cell }) => yearsToYearsMonthsUomRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club Avg Contract Residual Duration', accessorKey: 'avgContractResidualDuration', minSize: 240, Cell: ({ cell }) => monthsToYearsMonthsUomRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const valueColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'Club Fair Value', accessorKey: 'potentialPrice', minSize: 200, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Expected Price', accessorKey: 'expectedPrice', minSize: 230, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Residual Value', accessorKey: 'residualValue', minSize: 230, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Book Value', accessorKey: 'residualBookValue', minSize: 210, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Owned Rights', accessorKey: 'numOfOwnedPlayers', minSize: 200, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Avg Age', accessorKey: 'avgAge', minSize: 200, Cell: ({ cell }) => yearsToYearsMonthsUomRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club Avg Contract Duration', accessorKey: 'avgContractDuration', minSize: 280, Cell: ({ cell }) => monthsToYearsMonthsUomRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club Rank By Value', accessorKey: 'rankByValue', minSize: 210, Cell: tierGridRenderer(numOfClubs), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfClubs])

  const salaryColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'Club Fair Salary', accessorKey: 'salaryNetFair', size: 200, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Net Salary Sum', accessorKey: 'maxSalaryNet', size: 200, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Gross Salary Sum', accessorKey: 'maxSalaryGross', size: 210, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Rank By Salary', accessorKey: 'rankBySalary', size: 220, Cell: tierGridRenderer(numOfClubs), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Club Avg Tier Net Salary', accessorKey: 'avgSalaryNetCluster', size: 190, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Avg League Net Salary', accessorKey: 'avgLeagueSalaryNet', size: 210, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Fair Salary Abs Deviation', accessorKey: 'salaryAbsoluteDeviation', size: 220, Cell: salaryDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club Tier Net Salary Abs Deviation', accessorKey: 'absSalaryNetClusterDeviation', size: 220, Cell: tierDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Club League Net Salary Abs Deviation', accessorKey: 'absLeagueSalaryNetDeviation', size: 230, Cell: leagueDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfClubs])

  const kpiColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'playersAnnualCost', accessorKey: 'playersAnnualCost', size:240,Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubCostPerPoint', accessorKey: 'costPerPoint', size:240,Header: currencyHeaderRenderer, Cell: ({ cell }) => {
      const value = cell.getValue();
      return value === 0 ? 'N.A' : decimalNumberGridRenderer({ cell });
  }, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubRevenueGrowth', accessorKey: 'revenueGrowth', size: 240, Cell: percentageFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubProjectedRevenueGrowth', accessorKey: 'projectedRevenueGrowth', size: 210, Cell: percentageFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubRevenueShare', accessorKey: 'revenueShare', size: 240, Cell: percentageFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubNetProfitLoss', accessorKey: 'netProfitLoss', size: 240,Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubPlayerTrading', accessorKey: 'playerTrading', size: 240, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubCapitalGain', accessorKey: 'capitalGain', size: 240,Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'clubFinancialSustainabilityIndex', accessorKey: 'financialSustainabilityIndex', size: 220, Cell: percentageFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfClubs])

  const marketColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'expenditure5yAvg', accessorKey: 'expenditure5yAvg' },
    { header: 'income5yAvg', accessorKey: 'income5yAvg' },
    { header: 'netIncome5yAvg', accessorKey: 'netIncome5yAvg' },
    { header: 'topSale', accessorKey: 'maxTr' },
    { header: 'topPurchase', accessorKey: 'topPurchase' },
    { header: 'expenditureSeason', accessorKey: 'expenditureSeason' },
    { header: 'incomeSeason', accessorKey: 'incomeSeason' },
    { header: 'netIncomeSeason', accessorKey: 'netIncomeSeason' },
    { header: 'startingRoasterValue', accessorKey: 'startingRoasterValue' },
    { header: 'currentRoasterValue', accessorKey: 'potentialPrice' },
    { header: 'appreciationDepreciation', accessorKey: 'appreciationDepreciation' },
    { header: 'pointsSeason', accessorKey: 'pointsSeason' },
    { header: 'costPerPoint', accessorKey: 'costPerPoint' },
    { header: 'valuePerPoint', accessorKey: 'valuePerPoint' }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const userToken = useSelector(state => state.user.token)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [clubs, setClubs] = useState(props.clubs)
  const [columns, setColumns] = useState(generalColumns)
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnOrder, setColumnOrder] = useState(columns.map(c => c.accessorKey))

  const [tabState, setTabState] = useState('general')
  const [filterOpen, setFilterOpen] = useState(false)

  const isBreakpointSm = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    const timeout = setTimeout(() => {
      let headerWrappers = document.getElementsByClassName('Mui-TableHeadCell-Content-Wrapper')
      for (let i = 0; i < headerWrappers.length; i++) {
        headerWrappers.item(i).removeAttribute('title')
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [columns])

  useEffect(() => {
    setColumnOrder(columns.map(c => c.accessorKey))
  }, [columns])

  useEffect(() => {
    setNumOfClubs(clubsTotal)
  }, [clubsTotal])

  useEffect(() => {
    setNumOfClubClusters(clubClusters.length)
  }, [clubClusters])

  useEffect(() => {
    if (!props.disableFilters) {
      setFilterOpen(props.filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterOpen])

  useEffect(() => {
    if (!props.disableFilters) {
      props.setFilterOpen(filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOpen])

  useEffect(() => {
    if (isBreakpointSm) {
      setColumnVisibility(prevState => ({ ...prevState, count: true }))
    } else {
      setColumnVisibility(prevState => ({ ...prevState, count: false }))
    }
  }, [isBreakpointSm])

  useEffect(() => {
    if (clubsTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    setClubs(props.clubs)
  }, [props.clubs])

  useEffect(() => {
    let newColumns = []
    switch (tabState) {
      case 'general':
        newColumns = generalColumns
        break
      case 'players':
        newColumns = playersColumns
        break
      case 'value':
        newColumns = valueColumns
        break
      case 'salary':
        newColumns = salaryColumns
        break
      case 'kpi':
        newColumns = kpiColumns
        break
      case 'market':
        newColumns = marketColumns
        break
      default:
        console.warn('[StdClubsTable] unhandled tab state selected: ' + tabState)
        break
    }
    setColumns(newColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState, numOfClubs, numOfClubClusters])

  const toggleFilterOpen = (e) => {
    e.preventDefault()
    setFilterOpen(prevState => !prevState)
  }

  const handleTabClick = (e) => {
    switch (e.target.id) {
      case 'generalBtn':
        setTabState('general')
        break
      case 'playersBtn':
        setTabState('players')
        break
      case 'valueBtn':
        setTabState('value')
        break
      case 'salaryBtn':
        setTabState('salary')
        break
      case 'kpiBtn':
        setTabState('kpi')
        break
      case 'marketBtn':
        setTabState('market')
        break
      default:
        console.warn('[StdClubsTable] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '3rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            mr: 2
          }}>
            <TranslatedContent code='clubs' defaultValue='Clubs' />
          </Typography>
          <Tooltip title={<TranslatedContent code='clubsInfo' description />}>
            <InfoIcon fill='#FFFFFF' height='20px' />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: { xs: 'none', xl: 'flex' }, flexDirection: 'row' }}>
            <Button id='generalBtn' variant='stdTab' className={tabState === 'general' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='general' defaultValue='General' />
            </Button>
            <Button id='playersBtn' variant='stdTab' className={tabState === 'players' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='players' defaultValue='Players' />
            </Button>
            <Button id='valueBtn' variant='stdTab' className={tabState === 'value' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='value' defaultValue='Value' />
            </Button>
            <Button id='salaryBtn' variant='stdTab' className={tabState === 'salary' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='salary' defaultValue='Salary' />
            </Button>
            <Button id='kpiBtn' variant='stdTab' className={tabState === 'kpi' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='kpi' defaultValue='Kpi' />
            </Button>
            {/* <Button id='marketBtn' variant='stdTab' className={tabState === 'market' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
            <TranslatedContent code='market' defaultValue='Market' />
          </Button> */}
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block', xl: 'none' } }}>
            <TabSelect tabState={tabState} setTabState={setTabState} />
          </Box>
          <Box sx={{ display: props.disableFilters ? 'none' : 'block' }}>
            <IconButton onClick={toggleFilterOpen} sx={{ backgroundColor: '#FFFFFF', borderRadius: '50%', p: 1, ml: 3, '&:hover': { backgroundColor: '#FFFFFF' } }}>
              <TuneIcon sx={{ color: '#04A8A3' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 2, width: '100%', mt: 1, mb: { xs: 2, sm: 4 } }} />
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, width: '100%', justifyContent: 'center', mb: 2 }}>
        <TabSelect tabState={tabState} setTabState={setTabState} />
      </Box>
      <StdDataGrid
        columns={columns}
        data={clubs}
        rowId={(club) => club.clubId}
        rowCount={props.rowCount}
        enablePagination={props.enablePagination}
        manualPagination={props.manualPagination}
        page={props.page}
        pageSize={props.pageSize}
        onPaginationChange={props.onPaginationChange}
        sorting={props.sorting}
        onSortingChange={props.onSortingChange}
        columnVisibility={columnVisibility}
        columnOrder={columnOrder}
        onColumnOrderChange={setColumnOrder}
        enableBottomToolbar={props.enableBottomToolbar} />
    </Box>
  )
})

export default StdClubsTable